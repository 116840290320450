<template>
  <Transition name="fade">
    <LoadingStatus v-if="loading" :status="loadingStatus" :progress="loadingProgress" />
  </Transition>
</template>

<script>
import Sighting from '@/components/Sighting.vue'
import LoadingStatus from '@/components/controls/LoadingStatus.vue'

import { render, h } from 'vue'

import distance from '@turf/distance'

const MAX_LAYERS = 20

export default {
  inject: ['mapbox', 'map'],

  data () {
    return {
      loading: true,

      loaded: 0,
      total: null,

      count: null,

      popup: null,
      touch: false
    }
  },

  mounted () {
    this.popup = new this.mapbox.Popup({
      anchor: 'top',
      offset: [0, 5],
      closeButton: false,
      closeOnClick: false,
      maxWidth: '350px'
    })

    if (this.map._loaded) {
      this.initializeMapLayers()
    } else {
      this.map.on('load', () => {
        this.initializeMapLayers()
      })
    }

    this.loadSightings()
  },

  activated () {
    this.featureLayerIds.forEach(layer => {
      this.toggleLayer(layer, true)
    })
  },

  deactivated () {
    this.featureLayerIds.forEach(layer => {
      this.toggleLayer(layer, false)
    })
  },

  computed: {
    featureLayerIds () {
      return [...Array(MAX_LAYERS).keys()].map(i => [`dots-${i}`, `dots-hover-${i}`, `species-${i}`]).flat()
    },

    sourceIds () {
      return [...Array(MAX_LAYERS).keys()].map(i => `sightings-${i}`)
    },

    loadingProgress () {
      if (this.total) {
        return 100 * this.loaded / this.total
      }
    },

    loadingStatus () {
      if (this.count) {
        const format = new Intl.NumberFormat()
        return `Loading ${format.format(this.count)} BirdNET sightings...`
      } else {
        return 'Loading BirdNET sightings...'
      }
    }
  },

  methods: {
    initializeMapLayers () {
      this.map.on('sourcedata', (e) => {
        if (e.isSourceLoaded && e.sourceId.match(/^sightings\-/) && !e.sourceCacheId) {
          this.loaded += 1

          if (this.loaded == this.total) {
            setTimeout(() => this.loading = false, 1000)
          }
        }
      })

      this.map.on('click', this.featureLayerIds, (e) => {
        const sighting = e.features[0]
        const coords = sighting.geometry.coordinates

        this.map.flyTo({ center: coords })
      })

      this.map.on('mouseenter', this.featureLayerIds, (e) => {
        this.map.getCanvas().style.cursor = 'pointer'

        const sighting = e.features[0]
        this.showPopup(sighting)
      })

      this.map.on('touchstart', this.featureLayerIds, (e) => {
        const sighting = e.features[0]
        this.showPopup(sighting)
      })

      this.map.on('mouseleave', this.featureLayerIds, (e) => {
        this.map.getCanvas().style.cursor = ''
        this.popup.remove()
      })
    },

    async loadSightings () {
      const response = await fetch('https://app.birdweather.com/birdnet/sightings.json')
      const json = await response.json()

      this.count = json.count
      this.total = json.urls.length

      json.urls.forEach((url, index) => {
        if (this.map._loaded) {
          this.addSource(url, index)
        } else {
          this.map.on('load', () => this.addSource(url, index))
        }
      })
    },

    addSource(url, index) {
      const sourceName = `sightings-${index}`

      this.map.addSource(sourceName, {
        type: 'geojson',
        data: url
      })

      this.map.addLayer({
        id: `dots-${index}`,
        source: sourceName,
        type: 'circle',
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            // Zoom is 2 -> circle radius will be 1px
            2, 1,
            // Zoom is 9 -> circle radius will be 6px
            9, 6
          ],
          'circle-color': ['get', 'color']
        },
        maxzoom: 9
      })

      this.map.addLayer({
        id: `dots-hover-${index}`,
        source: sourceName,
        type: 'circle',
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            // Zoom is 2 -> circle hover radius will be 3px
            2, 3,
            // Zoom is 9 -> circle hover radius will be 9px
            9, 9
          ],
          'circle-color': ['get', 'color'],
          'circle-opacity': 0.001
        },
        maxzoom: 9
      })

      this.map.addLayer({
        id: `species-${index}`,
        source: sourceName,
        type: 'symbol',
        layout: {
          'icon-image': ['image',
            ['concat', 'species-', ['get', 'speciesId'], '-', ['get', 'color']]
          ],
          'icon-allow-overlap': true,
          'icon-size': [
            'interpolate', ['linear'], ['zoom'],
            // Zoom is 9 -> icon size will be 0.25x
            9, 0.25,
            // Zoom is 20 -> icon size will be 0.75x
            20, 0.75
          ]
        },
        minzoom: 9
      })
    },

    toggleLayer (layer, visible) {
      if (this.map.getLayer(layer)) {
        const visibility = visible ? 'visible' : 'none'
        this.map.setLayoutProperty(layer, 'visibility', visibility)
      }
    },

    showPopup (sighting) {
      const coords = sighting.geometry.coordinates
      const props = sighting.properties

      const target = document.createElement('div')
      render(h(Sighting, {
        species: {
          commonName: props.commonName,
          scientificName: props.scientificName,
          thumbnailUrl: `https://app.birdweather.com/species/${props.speciesId}.png`,
          color: props.color
        },
        timestamp: new Date(props.timestamp),
        score: props.score
      }), target)

      this.popup
        .setLngLat(coords)
        .setHTML(target.outerHTML)
        .addClassName('detection-marker-popup')
        .addTo(this.map)
    },

    autozoom () {
      const center = this.map.getCenter().toArray()
      const visibleFeatures = this.sourceIds.map(id => this.map.querySourceFeatures(id)).flat()

      if (visibleFeatures.filter(f => distance(f.geometry.coordinates, center) < 50).length > 50) {
        this.map.zoomTo(8)
      } else if (visibleFeatures.filter(f => distance(f.geometry.coordinates, center) < 100).length > 50) {
        this.map.zoomTo(7)
      } else if (visibleFeatures.filter(f => distance(f.geometry.coordinates, center) < 250).length > 50) {
        this.map.zoomTo(6)
      } else if (visibleFeatures.filter(f => distance(f.geometry.coordinates, center) < 500).length > 50) {
        this.map.zoomTo(5)
      }
    }
  },

  watch: {
    loading (loading) {
      if (!loading) {
        this.autozoom()
      }
    }
  },

  components: {
    LoadingStatus
  }
}
</script>
