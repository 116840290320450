<template>
  <div class="detection">
    <SpeciesThumbnail class="d-block medium rounded-circle" :species="species" />

    <div class="detection-main">
      <div class="detection-info flex-grow-1">
        <p class="mb-0">
          {{ species.commonName }} <em class="text-muted">({{ species.scientificName }})</em><br />
        </p>

        <p class="small text-muted mb-0">
          {{ this.formatDate(timestamp) }} @ {{ this.formatTimestamp(timestamp) }}
          ({{ this.timeSince(timestamp) }})
        </p>
      </div>

      <div class="detection-score flex-shrink-0" v-if="score">
        <p class="m-0">{{ score.toFixed(1) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SpeciesThumbnail from '@/components/SpeciesThumbnail.vue'

import { formatTimestamp, formatDate, timeSince } from '@/lib/formatting'

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    timestamp: {
      type: Date,
      required: true
    },

    score: {
      type: Number
    }
  },

  methods: {
    formatTimestamp,
    formatDate,
    timeSince
  },

  components: {
    SpeciesThumbnail
  }
}
</script>
