<template>
</template>

<script>
import { century, equationOfTime, declination } from 'solar-calculator'
import circle from '@turf/circle'

const antipode = ([longitude, latitude]) => [longitude + 180, -latitude]

const WIDGETS = {
  meridian: {
    color: 'blue',
    width: 5,
    coordinates: [
      [0, -90],
      [0, 90]
    ]
  },

  antimeridian: {
    color: 'green',
    width: 5,
    coordinates: [
      [180, -90],
      [180, 90]
    ]
  },

  equator: {
    color: 'purple',
    width: 2,
    coordinates: [
      [-180, 0],
      [180, 0]
    ]
  }
}

export default {
  inject: ['mapbox', 'map'],

  props: {
    time: {
      type: Date,
      default: () => new Date()
    },

    color: {
      type: String,
      default: '#000000'
    },

    opacity: {
      type: Number,
      default: 0.25
    },

    outline: {
      type: Boolean,
      default: false
    },

    widgets: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    if (this.map._loaded) {
      this.initializeMapLayers()
    } else {
      this.map.on('load', () => {
        this.initializeMapLayers()
      })
    }
  },

  methods: {
    initializeMapLayers() {
      this.map.addSource('solar-terminator', {
        type: 'geojson',
        data: this.geoJSON
      })

      this.map.addLayer({
        id: 'solar-terminator',
        type: 'fill',
        source: 'solar-terminator',
        paint: {
          'fill-color': this.color,
          'fill-opacity': this.opacity
        }
      })

      if (this.outline) {
        this.map.addSource('solar-terminator-points', {
          type: 'geojson',
          data: this.geoJSONPoints
        })

        this.map.addLayer({
          id: 'solar-terminator-outline',
          type: 'circle',
          source: 'solar-terminator-points',
          paint: {
            'circle-color': 'red',
            'circle-radius': 5
          }
        })
      }

      if (this.widgets) {
        Object.entries(WIDGETS).forEach(([name, { color, width, coordinates }]) => {
          this.map.addSource(name, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coordinates
              }
            }
          })

          this.map.addLayer({
            id: name,
            type: 'line',
            source: name,
            paint: {
              'line-color': color,
              'line-width': width
            }
          })
        })

        this.map.addSource('sun', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: this.sun
            }
          }
        })

        this.map.addLayer({
          id: 'sun',
          type: 'circle',
          source: 'sun',
          paint: {
            'circle-color': 'yellow',
            'circle-radius': 50
          }
        })
      }
    },

    insertPoleNotch (coords) {
      const pole = -90 * Math.sign(this.declination)

      for (let i = 1; i < coords.length; ++i) {
        let [lon, lat] = coords[i]
        let [lastLon, lastLat] = coords[i-1]

        if (Math.abs(lon - lastLon) > 180) {
          let delta = lon > lastLon ? 360 : -360

          let newCoords = [
            [lastLon, pole],
            [lastLon + delta, pole],
            [lastLon + delta, lastLat]
          ]

          return [
            ...coords.slice(0, i),
            ...newCoords,
            ...coords.slice(i)
          ]
        }
      }

      return coords
    }
  },

  computed: {
    geoJSONPoints () {
      return {
        type: 'Feature',
        geometry: {
          type: 'GeometryCollection',
          geometries: this.geoJSON.geometry.coordinates[0].map((point) => {
            return {
              type: 'Point',
              coordinates: point
            }
          })
        }
      }
    },

    geoJSON () {
      const feature = circle(this.center, 90, { units: 'degrees', steps: 64 })
      feature.geometry.coordinates[0] = this.insertPoleNotch(feature.geometry.coordinates[0])
      return feature
    },

    center () {
      return antipode(this.sun)
    },

    century () {
      return century(this.time)
    },

    declination () {
      return declination(century(this.time))
    },

    sun () {
      const day = new Date(+this.time).setUTCHours(0, 0, 0, 0)
      const t = this.century
      const longitude = (day - this.time) / 864e5 * 360 - 180
      return [longitude - equationOfTime(t) / 4, this.declination]
    }
  },

  watch: {
    time () {
      const terminator = this.map.getSource('solar-terminator')
      if (terminator) { terminator.setData(this.geoJSON) }

      const points = this.map.getSource('solar-terminator-points')
      if (points) { points.setData(this.geoJSONPoints) }

      const sun = this.map.getSource('sun')
      if (sun) {
        sun.setData({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: this.sun
          }
        })
      }
    }
  }
}
</script>
